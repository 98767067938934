/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Roboto:300,400,500,600,700");
// Global vendors
// @import "~bootstrap/dist/css/bootstrap.css";
// => Material
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "bootstrap/dist/css/bootstrap.min.css";
@import "bootstrap-icons/font/bootstrap-icons.css";
@import 'ngx-toastr/toastr';

@import "~@ng-select/ng-select/themes/default.theme.css";


// Global fonts
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "./assets/plugins/flaticon/flaticon.css";
@import "./assets/plugins/flaticon2/flaticon.css";
@import "./assets/plugins/keenthemes-icons/font/ki.css";

// Metronic styles (replace these path when using RTL css below)
@import "./assets/sass/style.angular.scss";

// For RTL, check this documentation https://keenthemes.com/metronic/?page=docs&section=angular-rtl
// @import "./assets/sass/style.angular.rtl.css";

// Default Layout themes
@import "./assets/sass/themes/layout/header/base/light.scss";
@import "./assets/sass/themes/layout/header/menu/light.scss";
@import "./assets/sass/themes/layout/brand/dark.scss";
@import "./assets/sass/themes/layout/aside/dark.scss";

// Header themes
// .header-base-light {
//     @import "./assets/sass/themes/layout/header/base/light.scss";
// }

// .header-base-dark {
//     @import "./assets/sass/themes/layout/header/base/dark.scss";
// }

// Header Menu themes
// .header-menu-light {
//     @import "./assets/sass/themes/layout/header/menu/light.scss";
// }
// .header-menu-dark {
//     @import "./assets/sass/themes/layout/header/menu/dark.scss";
// }

// Brand themes
.brand-dark {
  @import "./assets/sass/themes/layout/brand/dark.scss";
}

.brand-light {
  @import "./assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// .aside-dark {
//   @import "./assets/sass/themes/layout/aside/dark.scss";
// }

// .aside-light {
//   @import "./assets/sass/themes/layout/aside/light.scss";
// }

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
  display: none !important;
}

.angular-bootstrap-table {
  overflow-x: auto;
}

.angular-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead th.sortable-active {
    color: #3699FF !important;
  }
}

.cursor-default {
  cursor: default !important;
}

.progress-modal {
  height: 3px;
  width: 100%;
}

.form-check-input {
  border: 2px solid;
  border-color: $primary;

  &:checked {
    background-color: $primary;
    border-color: $primary;
  }
}

.modal-dialog{
  margin: 0;
}
@media (min-width: 576px) {
  .modal-dialog{
    margin: 1.75rem auto;
  }
}

.form-floating {
  position: relative;

  .ng-select-container {
      height: calc(3.5rem + 2px) !important;
      line-height: 1.25;
  }

  label {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; // allow textareas
      padding: 1rem 0.75rem;
      overflow: hidden;
      text-align: start;
      text-overflow: ellipsis;
      white-space: nowrap;
      pointer-events: none;
      border: 1px solid transparent; // Required for aligning label's text with the input as it affects inner box model
      transform-origin: 0 0;
      transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
      color: #989898;
  }
  input:-webkit-autofill + label {
    background-color: transparent;
  } 

  input:disabled + label::after {
      background-color: transparent !important;
  }

  input:-webkit-autofill {
    background-clip: text;
    }

  .ng-select-container {
      padding-top: 1.625rem;
      padding-bottom: .625rem;

      .ng-input {
          top: unset !important;
      }
  }

  ng-select {
      ~label {
          transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
          z-index: 1001;
      }


      &:focus .ng-select-container {
          border-color: #86b7fe;
          outline: 0;
          box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
      }

      &.is-valid .ng-select-container {
          border-color: #198754 !important;
      }

      &.is-valid.ng-select-focused .ng-select-container {
          box-shadow: 0 0 0 0.25rem rgb(25 135 84 / 25%);
      }

      &.is-invalid .ng-select-container {
          border-color: #dc3545 !important;

      }

      &.is-invalid.ng-select-focused .ng-select-container {
          box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%);
      }
  }
}

.btn-success{
  &:hover {
    color: #ffffff !important;
  }
}

.custom-error-toast {
  background-color: #fff8dd !important;
  border: solid 1px #ffc1ba;
  color: #333333 !important; /* Set font color to black */
}

.custom-error-toast .toast-warning {
  color: #f1ad4e !important;
}